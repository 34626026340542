

.menu-dropdown {
    display: inline-block;
    position: relative;
    float: right;
}

.inline-dropdown {
    /*display: inline-block;*/
    /*position: relative;*/
    z-index: 600;
}

.menu-dropdown-content {
    display: none;
    position: absolute;
    width: max-content;
    cursor: pointer;
    /*overflow: auto;*/
    margin: auto auto auto -25px;
    /*margin-top: 10px;*/
    z-index: 1100;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
}

.inline-dropdown-content {
    display: none;
    position: absolute;
    width: max-content;
    margin: auto;
    cursor: pointer;
    /*overflow: auto;*/
    margin-left: -25px;
    /*margin-top: 10px;*/
    z-index: 1050;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
}

.menu-dropdown-content-right {
    display: none;
    position: absolute;
    width: max-content;
    margin: auto;
    /*overflow: auto;*/
    margin-left: -25px;
    margin-top: 15px;
    z-index: 1000;
    background-color: #77dd77;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
}

.menu-right {
    right: 0;
}

.menu-dropdown:hover .menu-dropdown-content {
    display: block;
}

.inline-dropdown:hover .inline-dropdown-content {
    display: block;
}

.menu-dropdown-content a {
    float: none;
    display: block;
    color: #ffffff;
    padding: 15px;
    text-decoration: none;
}

.menu-dropdown-content a:hover {
    color: #FFFFFF;
    background-color: #2e522e;
    font-weight: bold;
}

.basic-menu-item {
    font-size: 42px;
    color: rgb(65,69,72,1);
}

.basic-menu-item:hover {
    color: cornflowerblue;
}

.basic-dropmenu-item {
    font-size: 42px;
    color: black;
    text-align: center;
    z-index: 1100;
}

.inline-dropmenu-item {
    font-size: 14px;
    background-color: rgb(246,248,250,1);
    color : black ;
    text-align: center;
}

.inline-dropmenu-item:hover {
    background-color: rgb(112, 113, 115);
    color : white ;
}

.basic-dropmenu-icon {
    font-size: 42px ;
}

.basic-dropmenu-text {
    font-size: 24px ;
    text-align: right;
}

.basic-dropmenu-item:hover {
    background-color: darkgrey;
    color: white ;
}

