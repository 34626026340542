
.lv-content {
    margin-left: 30px;
}

.lv-logo {
    width: 100px;
    /*padding-top: 10px;*/
    padding-right: 10px;
}

.lv-date-sel {
    position: fixed;
    z-index: 900;
    top: 60px;
    margin-right: 0 ;
    padding-bottom: 10px;
    background-color: var(--std-secondary-blue);
    /*padding: 5px 5px 5px 20%;*/
}

.lv-inst {
    /*margin-top: 30px;*/
    /*margin-left: 15px;*/
    /*margin-right: 70px;*/
}

.lv-normal {
    margin-top: 70px;
}

.lv-top-offset {
    margin-top: 140px;
}

.lv-inst-l {
    /*Lift Installation Left hand side*/
    display: inline-block;
    width: 50%;
}

.lv-inst-r {
    /*Lift Installation Right hand side*/
    display: inline-block;
    width: 50%;
    min-height: 900px;
    vertical-align: top;
}

.lv-st {
    vertical-align: top;
    margin-top: 0px;
    margin-left: 30px;
}

.lv-st-1 {
    /*Lift Status Column 1*/
    display: inline-block;
    width: 30%;
    vertical-align: top;
}

.lv-st-2 {
    /*Lift Status Column 2*/
    display: inline-block;
    width: 13%;
    vertical-align: top;
    text-align: center;
}

.lv-st-3 {
    /*Lift Status Column 3*/
    display: inline-block;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: top;
    text-align: center;
}

.lv-st-4 {
    /*Lift Status Column 1*/
    display: inline-block;
    width: auto;
    vertical-align: top;
}

.lv-raw {
    /*Lift Raw Data*/
    vertical-align: top;
    margin-top: 10px;
    margin-left: 30px;
}

.lv-time {
    /*Lift Raw Data*/
    vertical-align: top;
    width: 100%;
    margin-top: 10px;
    margin-left: 5px;
}

.lv-dist {
    /*Lift Raw Data*/
    vertical-align: top;
    width: 100%;
    margin-top: 10px;
    margin-left: 10px;
}

.lv-dist-group {
    display: inline-block;
}

.lv-dist-chart {
    display: inline-block;
    width: 50%;
}

.lv-dist-text {
    font-size: 12px;
    vertical-align: top;
    color: var(--std-secondary-blue);
}

.lv-tr {
    /* Lift triggers */
    width: 100% ;
    vertical-align: top;
    margin-top: 10px;
    margin-left: 20px;
}

.lv-ev {
    /*Lift Event*/
    vertical-align: top;
    width: 100%;
    display: inline-block;
    margin-top: 10px;
    margin-left: 20px;
}

.lv-an {
    /*Lift Analytics */
    vertical-align: top;
    width: 100%;
    display: inline-block;
    margin-top: 10px;
    margin-left: 20px;
}

.lv-sim {
    /*Lift SIM*/
    vertical-align: top;
    width: 100%;
    /*display: inline-block;*/
    padding-left: 20px;
    margin-top: 10px;
}

.lv-sim {
    display: inline-block;
    vertical-align: top;
}

.lv-sim-l {
    display: inline-block;
    width: 48%;
    vertical-align: top;
}

.lv-sim-r {
    display: inline-block;
    width: 48%;
    padding: 5px;
    vertical-align: top;
}

.lv-sim-d {
    display: block;
    width: 100%;
    vertical-align: top;
}

.lv-sim-u {
    display: block;
}

.lv-sim-map {
    display: block;
    margin-left: 2%;
    margin-right: 2%;
    min-height: 400px;
}

@media only screen and (max-width: 800px), only screen and (max-height: 400px) {

    .lv-sim {
        display: block;
    }

    .lv-sim-l {
        display: block;
        width: 100%;
    }

    .lv-sim-r {
        display: block;
        width: 100%;
    }

    .lv-content {
        margin-left: 0px;
    }

    .lv-date-sel {
        position: fixed;
        z-index: 900;
        top: 90px;
        /*position: unset;*/
        /*z-index: unset;*/
        /*top: 140px;*/
        width: 100%;
        background-color: var(--std-secondary-blue);
    }

    .lv-inst-l {
        width: 100%;
    }

    .lv-inst-r {
        width: 100%;
    }

    .lv-st-1 {
        width: auto;
        margin-left: 15px ;
        margin-right: 5px ;
    }

    .lv-st-2 {
        width: auto;
        margin-left: 15px ;
        margin-right: 5px ;
    }

    .lv-st-3 {
        width: auto;
        margin-left: 15px ;
        margin-right: 5px ;
    }

    .lv-st-4 {
        width: auto;
        margin-left: 15px ;
        margin-right: 5px ;
    }

    .lv-dist-chart {
        display: block;
        width: 100%;
    }
}