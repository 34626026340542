.filter-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.filter-input {
    flex: 1 !important;
    padding: 10px !important;
    font-size: 16px !important;
    border: 1px solid lightgrey !important;
    border-right: none !important; /* Remove right border to merge with button */
    border-radius: 0 25px 25px 0 !important; /* Rounded corners on the right side */
    outline: none !important;
    height: 40px !important; /* Set a fixed height */
    box-sizing: border-box !important; /* Ensure padding and border are included in the element's total width and height */
}

.filter-clear-button {
    height: 40px; /* Set the same fixed height as the input */
    padding: 0 10px; /* Adjust padding to center the icon */
    font-size: 16px;
    color: #fff;
    background-color: #72b222;
    border: 1px solid #ccc;
    border-radius: 20px 0 0 20px; /* Rounded corners on the left side */
    border-right: none; /* Remove right border to merge with input */
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.filter-search-button {
    height: 40px; /* Set the same fixed height as the input */
    padding: 0 10px; /* Adjust padding to center the icon */
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: 1px solid #ccc;
    border-radius: 0 25px 25px 0; /* Rounded corners on the right side */
    border-left: none; /* Remove left border to merge with input */
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

/*.filter-clear-button {*/
/*    height: 40px; !* Set the same fixed height as the input *!*/
/*    padding: 0 10px; !* Adjust padding to center the icon *!*/
/*    font-size: 16px;*/
/*    color: #fff;*/
/*    background-color: #72b222;*/
/*    border: 1px solid #ccc;*/
/*    border-radius: 25px 0 0 25px; !* Rounded corners on the right side *!*/
/*    border-right: none; !* Remove left border to merge with input *!*/
/*    cursor: pointer;*/
/*    outline: none;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    box-sizing: border-box; !* Ensure padding and border are included in the element's total width and height *!*/
/*}*/

.filter-clear-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.filter-search-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.filter-search-button:hover {
    background-color: #0056b3;
}