

.diag-detail {
    width: auto;
}


.diahdr {
    font-size: 10px;
    color: darkblue;
}

.diarow {
    border-bottom: 0.5px solid darkgrey;
}

.diacolhdr {
    display: inline-block;
    font-size: 36px ;
    width: 80px;
    text-align: center;
}

.diarow:hover {
    background-color: #eafdfd;
}

/*.diacell-unsafe {*/
/*    display: inline-block;*/
/*    border: 0.25px solid black ;*/
/*    background-color: #de0d34;*/
/*    min-width: 30px;*/
/*    height: 15px;*/
/*    text-align: center;*/
/*    margin-right: 8px;*/
/*    cursor : pointer;*/
/*    padding : 2px ;*/
/*    font-size: x-small;*/
/*    color: white ;*/
/*}*/

/*.diacell-safe {*/
/*    display: inline-block;*/
/*    background-color: #f1fdf1;*/
/*    border: 0.25px solid gray ;*/
/*    !*width: 30px;*!*/
/*    height: 15px;*/
/*    min-width: 30px;*/
/*    text-align: center;*/
/*    margin-right: 8px;*/
/*    cursor : pointer;*/
/*    font-size: x-small;*/
/*    color: black;*/
/*    padding : 2px ;*/
/*}*/

/*.diacell-pressed {*/
/*    display: inline-block;*/
/*    background-color: #148ccc;*/
/*    border: 0.25px solid gray ;*/
/*    !*width: 30px;*!*/
/*    height: 15px;*/
/*    min-width: 30px;*/
/*    text-align: center;*/
/*    margin-right: 8px;*/
/*    cursor : pointer;*/
/*    font-size: x-small;*/
/*    color: white;*/
/*    padding : 2px ;*/
/*}*/

/*.diacell-warn {*/
/*    display: inline-block;*/
/*    background-color: #f8a02c;*/
/*    border: 0.25px solid gray ;*/
/*    !*width: 30px;*!*/
/*    height: 15px;*/
/*    min-width: 30px;*/
/*    text-align: center;*/
/*    margin-right: 8px;*/
/*    cursor : pointer;*/
/*    font-size: x-small;*/
/*    color: black;*/
/*    padding : 2px ;*/
/*}*/

/*.diacell-active {*/
/*    display: inline-block;*/
/*    background-color: #55f357;*/
/*    border: 0.25px solid gray ;*/
/*    !*width: 30px;*!*/
/*    height: 15px;*/
/*    min-width: 30px;*/
/*    text-align: center;*/
/*    margin-right: 8px;*/
/*    cursor : pointer;*/
/*    font-size: x-small;*/
/*    color: black;*/
/*    padding : 2px ;*/
/*}*/

/*.diacell-na {*/
/*    display: inline-block;*/
/*    background-color: darkgrey;*/
/*    border: 0.25px solid gray ;*/
/*    !*width: 30px;*!*/
/*    height: 15px;*/
/*    min-width: 30px;*/
/*    text-align: center;*/
/*    margin-right: 8px;*/
/*    cursor : pointer;*/
/*    font-size: x-small;*/
/*    color: white;*/
/*    padding : 2px ;*/
/*}*/

/*.diacell-ok {*/
/*    display: inline-block;*/
/*    background-color: #f1fdf1;*/
/*    border: 0.25px solid gray ;*/
/*    !*width: 30px;*!*/
/*    height: 15px;*/
/*    min-width: 30px;*/
/*    text-align: center;*/
/*    margin-right: 8px;*/
/*    cursor : pointer;*/
/*    font-size: x-small;*/
/*    color: black;*/
/*    padding : 2px ;*/
/*}*/

/*.diacell-tripped {*/
/*    display: inline-block;*/
/*    border: 0.25px solid black ;*/
/*    background-color: #de0d34;*/
/*    min-width: 30px;*/
/*    height: 15px;*/
/*    text-align: center;*/
/*    margin-right: 8px;*/
/*    cursor : pointer;*/
/*    padding : 2px ;*/
/*    font-size: x-small;*/
/*    color: white ;*/
/*}*/

/*.diacell-on {*/
/*    display: inline-block;*/
/*    background-color: #ff6961;*/
/*    border: 0.25px solid black ;*/
/*    width: 30px;*/
/*    height: 20px;*/
/*    text-align: center;*/
/*    margin-right: 8px;*/
/*    cursor : pointer;*/
/*}*/

.diacell {
    display: inline-block;
    border: 0.25px solid darkgrey;
    border-radius: 3px;
    height: 15px;
    text-align: center;
    margin-right: 8px;
    cursor: pointer;
    font-size: x-small;
    padding: 2px;
}

.dc-unsafe {
    background-color: #de0d34;
    color: white;
}

.dc-safe {
    background-color: #f1fdf1;
    border-color: gray;
    color: black;
}

.dc-pressed {
    background-color: #148ccc;
    border-color: gray;
    color: white;
}

.dc-warn {
    background-color: #f8a02c;
    border-color: gray;
    color: black;
}

.dc-active {
    background-color: #55f357;
    border-color: gray;
    color: black;
}

.dc-na {
    background-color: darkgrey;
    border-color: gray;
    color: white;
}

.dc-ok {
    background-color: #f1fdf1;
    border-color: gray;
    color: black;
}

.dc-tripped {
    background-color: #de0d34;
    color: white;
}

.dc-on {
    background-color: #ff6961;
}

.dc-selected {
    background-color: #8EA50BFF;
    color: white;
}

.dc-off {
    background-color: #f6f4f4;
}

.diacell-unused {
    background-color: white;
    text-decoration: line-through;
}

.diacell-selected {
    display: inline-block;
    background-color: #8EA50BFF;
    color: white;
    border: 0.25px solid black ;
    width: 30px;
    height: 20px;
    text-align: center;
    margin-right: 8px;
    cursor : pointer;
}

.diacell-spacer {
    display: inline-block;
    width: 10px;
    height: 30px;
    text-align: center;
}

.diacell-off {
    display: inline-block;
    border: 0.25px solid black ;
    background-color: #f6f4f4;
    width: 30px;
    height: 20px;
    text-align: center;
    margin-right: 8px;
    cursor : pointer;
}

.diacell-unused {
    display: inline-block;
    border: 0.25px solid black ;
    background-color: white;
    width: 30px;
    height: 20px;
    text-align: center;
    text-decoration: line-through;
    margin-right: 8px;
}

.diabittext {
    display: inline-block;
    writing-mode: vertical-rl;
    rotate: 190deg;
    margin-left: 15px;
}

.tlcl_red {
    background-color: red ;
    color: white ;
}

.tlcl_green {
    background-color: green ;
    color: white ;
}

.tlcl_blue {
    background-color: blue ;
    color: white ;
}

.tlcl_yellow {
    background-color: yellow ;
    color: black ;
}

.tlcl_orange {
    background-color: orange ;
    color: white ;
}

.tlcl_purple {
    background-color: purple ;
    color: white ;
}

.tlcl_grey {
    background-color: darkgrey ;
    color: white ;
}

.tlcl_magenta {
    background-color: magenta ;
    color: white ;
}

.tlcl_lightgreen {
    background-color: lightgreen ;
    color: black ;
}

.tlcl_cyan {
    background-color: cyan ;
    color: black ;
}

.chtt {
    background-color: var(--std-primary-green);
    color : black;
    border-radius: 12px;
    min-width: 340px ;
    /*max-width: 300px ;*/
    min-height: 180px;
    vertical-align: top;
    text-align: center;
    font-size: 14px;
    opacity: 0.9;
    display: inline-block;
    cursor: pointer;
    margin: 5px ;
    box-shadow: 3px 3px 6px #7c7878;
    border: 0.5px solid darkgray;
    /*position: relative;*/
}

.chtt1 {
    font-weight: bold;
    min-width: 250px;
    padding: 15px;
    text-align: center;
    color: white;
    font-size: x-large;
}

.chtt1s {
    text-align: center;
    color: white;
    font-size: small;
}

.chtt2 {
    display: table;
    width: auto;
    padding: 10px;
    text-align: left;
    margin-right: 10px;
}

.chttr {
    display: table-row;
}


.chtth {
    display: table-cell;
    font-weight: bold;
    padding: 5px;
    color: white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
}

.chtth-ct {
    text-align: center;
    display: table-cell;
    font-weight: bold;
    padding: 5px;
    color: white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
}

.chtt-item {
    display: table-cell;
    padding: 5px;
    color: white;
    max-width: 100px;
    word-wrap: break-word;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
}

.chtt-itemc {
    display: table-cell;
    padding: 5px;
    color: black;
    font-weight: bold;
    background-color: var(--std-secondary-blue);
    max-width: 120px;
    word-wrap: break-word;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
}

