

@media only screen and (max-width: 1599px) {

    .desktop {
        display: none;
    }

    .mobile-portrait-compact {
        display: none;
    }

    .mobile-landscape-compact {
        display: none;
    }

    .mobile-portrait-expanded {
        display: none;
    }

    .mobile-portrait-large {
        display: none;
    }

    .st-main-title {
        font-size: 2em;
        margin: 2px;
        padding: 2px;
    }

    .st-login-logo {
        width: 200px;
    }

    .st-login-detail {
        width: 100%;
    }

    .st-login-info {
        display: none;
    }
}

@media only screen and (max-width: 1199px) {

    .desktop {
        display: none;
    }

    .mobile-portrait-compact {
        display: none;
    }

    .mobile-landscape-compact {
        display: none;
    }

    .mobile-portrait-expanded {
        display: none;
    }

    .mobile-portrait-large {
        display: none;
    }

    .st-main-title {
        font-size: 2em;
        margin: 2px;
        padding: 2px;
    }

    .st-login-logo {
        width: 200px;
    }

    .st-login-detail {
        width: 100%;
    }

    .st-login-info {
        display: none;
    }
}

@media only screen and (max-width: 839px) {

    .app-container-item {
        flex: 1 0 100%;
    }

    .desktop {
        display: none;
    }

    .mobile-portrait-compact {
        display: none;
    }

    .mobile-landscape-compact {
        display: none;
    }

    .mobile-portrait-expanded {
        display: none;
    }

    .mobile-portrait-large {
        display: none;
    }

    .st-main-title {
        font-size: 2em;
        margin: 2px;
        padding: 2px;
    }

    .st-login-logo {
        width: 200px;
    }

    .st-login-detail {
        width: 100%;
    }

    .st-login-info {
        display: none;
    }
}

@media only screen and (max-width: 400px) {

    .desktop {
        display: none;
    }

    .mobile-portrait-compact {
        display: block;
    }

    .mobile-landscape-compact {
        display: none;
    }

    .mobile-portrait-expanded {
        display: none;
    }

    .mobile-portrait-large {
        display: none;
    }

    .std-inlined {
        display: block;
    }

    .st-main-title {
        font-size: 2em;
        margin: 2px;
        padding: 2px;
        display: block;
    }

    .st-login-logo {
        width: 200px;
    }

    .st-login-detail {
        width: 100%;
        box-sizing: border-box;
    }

    .st-login-detail input[type=text] {
        width: 100%;
        font-size: 20px;
    }

    .st-login-detail input[type=password] {
        width: 80%;
        font-size: 20px;
    }

    .st-login-info {
        display: none;
    }

    .st-lg-label {
    }

    .st-inset-form {
        padding: 0;
        width: 100%;
    }

    .app-top-user-menu {
        display: block;
        width: 100%;
        height: auto;
    }

    .app-top-menu-title {
        font-size: 16px;
        display: block;
        text-align: center;
        width: 100%;
        padding: 5px;
    }

    .menu-title {
        font-size: 16px;
        display: block;
        text-align: center;
        width: 100%;
        padding: 5px;
    }

    .menu-title-view {
        display: block;
        width: 100%;
        padding: 5px;
    }
}

@media only screen and (max-height: 500px) {
    .desktop {
        display: none;
    }

    .mobile-portrait-compact {
        display: none;
    }

    .mobile-landscape-compact {
        display: block;
    }

    .mobile-portrait-expanded {
        display: none;
    }

    .mobile-portrait-large {
        display: none;
    }

    .st-main-title {
        font-size: 2em;
        margin: 2px;
        padding: 2px;
    }

    .st-login-logo {
        width: 150px;
    }

    .st-login-detail {
        width: 100%;
    }

    .st-login-info {
        display: none;
    }
}
