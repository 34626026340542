.cls-1 {
    fill: #676766;
    stroke-width: 0px;
}

.cls-2 {
    fill: #8ea50d;
    stroke-width: 0px;
}

.cls-3 {
    fill: #404649;
    stroke-width: 0px;
}

.cls-4 {
    fill: #f6e820;
    stroke-width: 0px;
}

.cls-5 {
    fill: #cdcccc;
    stroke-width: 0px;
}

.cls-6 {
    fill: #56b038;
    stroke-width: 0px;
}

.cls-7 {
    fill: #999;
    stroke-width: 0px;
}

.cls-8 {
    fill: blue;
    stroke-width: 0px;
}

.cls-9 {
    fill: #cddf9c;
    stroke-width: 0px;
}

.cop-top-left-seg {
    fill: #cddf9c;
    stroke-width: 0px;
}

.cop-top-right-seg {
    fill: red;
    stroke-width: 0px;
}

.cop-bot-left-seg {
    fill: blue;
    stroke-width: 0px;
}

.cop-bot-right-seg {
    fill: cyan;
    stroke-width: 0px;
}

.cls-10 {
    fill: #f29699;
    stroke-width: 0px;
}

.cls-11 {
    fill: #fff;
    stroke-width: 0px;
}

.cls-12 {
    fill: red;
    stroke: #676766;
    stroke-width: 6px;
    stroke-miterlimit: 10;
}

.cls-13 {
    fill: pink;
    stroke: #676766;
    stroke-width: 3.63px;
    stroke-miterlimit: 10;
}

