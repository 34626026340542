

.home-background {
    /*margin-top: 70px;*/
    margin-left: 10px;
    margin-right: 70px;
}


.home-background::before {
    content: "";
    background: url('../assets/duo_render_background.jpeg');
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.35; /* Adjust this value to change the opacity */
    z-index: -1; /* Ensures the image stays behind your content */
}

.home-main {
    /*width: 70% ;*/
    display: inline-block;
}

.home-srch {
    display: inline-block;
}

.home-links {

}

.home-sim {
    width: 30% ;
    display: inline-block;
    vertical-align: top;
}

/* Container for item-containers */
.item-container-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

/* Parent container */
.item-container {
    display: flex;
    /*align-items: center;*/
    background-color: var(--std-white);
    width: 200px;
    height: 80px;
    border-radius: 12px;
    vertical-align: top;
    text-align: center;
    font-size: 14px;
    opacity: 0.9;
    cursor: pointer;
    margin: 2px ;
    box-shadow: 3px 3px 6px #7c7878;
    border: 0.5px solid darkgray;
}

.item-container-selected {
    background-color: var(--std-primary-green);
    color : rgb(246,248,250,1);
}

.item-container:hover {
    background-color: var(--std-primary-green);
    color : rgb(246,248,250,1);
}

/* Icon */
.item-container .icon {
    flex: 0 0 auto;
    height: 100%;
    padding-right: 10px;
    /* Add your icon styling here */
}

/* Text container */
.item-container .text-container {
    display: flex;
    flex-direction: column;
    /*justify-content: left;*/
    /*align-items: left;*/
    flex-grow: 1;
    vertical-align: top;
}

/* Title */
.item-container .text-container .title {
    font-weight: bold;
    text-align: left;
    margin-top: 5px;
    /* Add your title styling here */
    vertical-align: top;
}

/* Number */
.item-container .text-container .number {
    /* Add your number styling here */
    font-size: 52px;
}

.home-item {
    background-color: var(--std-white);
    color : black;
    border-radius: 12px;
    min-width: 240px ;
    max-width: 300px ;
    min-height: 160px;
    vertical-align: top;
    text-align: center;
    font-size: 14px;
    opacity: 0.9;
    display: inline-block;
    cursor: pointer;
    margin: 2px ;
    box-shadow: 3px 3px 6px #7c7878;
    border: 0.5px solid darkgray;
    position: relative;
}

.home-item:hover {
    background-color: var(--std-highlight);
    color : rgb(246,248,250,1);
}

.home-active {
    background-color: var(--std-ok);
    color: black ;
}

.home-inactive {
    background-color: var(--std-warning);
    color: white ;
}

.home-inerror {
    background-color: var(--std-error);
    color: black ;
}

.home-item-title {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    margin-right: 4px;
    padding-bottom: 15px ;
    text-align: left;
    color : var(--std-secondary-blue);
}

@media only screen and (max-width: 950px), only screen and (max-height: 400px) {
    .home-background {
        margin-left: 2px;
    }


    .home-main {
        display: block;
        width: 100%;
    }

    .home-sim {
        display: block;
    }

    .home-item {
        width: 100% ;
        max-width: 98% ;
        min-height: 140px;
        text-align: left;
    }
}

