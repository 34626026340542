

.commission-content {
    margin-left: 10px;
}

.form-label {
    font-size: 20px;
    color : var(--std-primary-grey);
    padding: 2px;
    display: inline-block;
}

.form-value {
    font-size: 20px;
    color : var(--std-secondary-blue);
    padding: 8px;
    display: inline-block;
}

.form-message {
    font-size: 10px;
    color : var(--std-secondary-blue);
    padding: 1px;
    font-style: italic;
}

.comm-item-title {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    padding: 8px ;
    text-align: left;
    color : var(--std-primary-grey);
}

.comm-select-node {
    background-color: var(--std-white);
    color : black;
    border-radius: 12px;
    min-width: 240px ;
    max-width: 300px ;
    min-height: 150px;
    vertical-align: top;
    text-align: center;
    font-size: 14px;
    opacity: 0.9;
    display: inline-block;
    cursor: pointer;
    box-shadow: 3px 3px 6px #7c7878;
    border: 0.5px solid darkgray;
    position: relative;
    margin: 5px;
}

.comm-select-node:hover {
    background-color: var(--std-highlight);
    color : rgb(246,248,250,1);
}

.comm-select-node-selected {
    background-color: var(--std-primary-green);
    color : black;
    border-radius: 12px;
    min-width: 240px ;
    max-width: 300px ;
    min-height: 180px;
    vertical-align: top;
    text-align: center;
    font-size: 14px;
    opacity: 0.9;
    display: inline-block;
    cursor: pointer;
    margin: 5px ;
    box-shadow: 3px 3px 6px #7c7878;
    border: 0.5px solid darkgray;
    position: relative;
}


.bt-block {
    width: auto ;
    display: inline-block;
    border: 1px solid darkgray;
    margin: 1px;
    border-radius: 3px;
    padding: 2px;
    text-align: center;
    font-size: 18px;
}

.bt-ok {
    background-color: var(--std-primary-green);
    color : white;
}

.bt-fail {
    background-color: var(--std-secondary-blue);
    color : white;
}


@media only screen and (max-width: 950px), only screen and (max-height: 400px) {

    .commission-content {
        margin-left: 5px;
    }
}
