
.plc-container {
    border: 1px solid darkgray;
    border-radius: 15px ;
    padding: 10px;
    background-color: #63b264;
}

.plc-row {

}

.plc-cell {
    width: 60px ;
    text-align: center;
    color: black ;
    font-size: 48px;
    display: inline-block;
    padding: 5px;
}

