


input[type=text] {
    background-color: white;
    background-repeat: no-repeat;
    width: auto;
    border-radius: 5px;
    border: 0.5px solid lightgrey;
    font-size: 12px;
    padding: 10px;
    /*vertical-align: top;*/
}

.non-editable-text {
    padding: 5px;
    min-width: 250px;
    min-height: 60px;
    width: auto;
    border-radius: 5px;
    border: 0.5px solid lightgrey;
    background-color: rgba(255, 255, 245, 0.85);
}

textarea {
    background-color: white;
    background-repeat: no-repeat;
    width: auto;
    border-radius: 5px;
    border: 0.5px solid lightgrey;
    font-size: 12px;
    padding: 5px;
}

input[type=number] {
    background-color: white;
    background-repeat: no-repeat;
    padding: 6px;
    width: auto;
    border-radius: 5px;
    border: 0.5px solid lightgrey;
    font-size: large;
    /*margin-left: 10px;*/
    vertical-align: top;
}


input[type=password] {
    background-color: white;
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 10px;
    width: 90%;
    /*border-radius: 5px;*/
    border: 0.5px solid lightgrey;
}


input[type=datetime-local] {
    border: 0.5px solid lightgrey ;
    border-radius: 3px;
    padding: 2px;
    color: darkblue;
}

input[type="text"]:disabled {
    background-color: rgba(255, 255, 245, 0.85);
}


.login-page input[type="text"] {
    /*margin-bottom: -1px;*/
    /*border-bottom-left-radius: 0;*/
    /*border-bottom-right-radius: 0;*/
    border-radius: 5px;
    border: 0.5px solid lightgrey;
    font-size: 22px;
}

.login-page input[type="password"] {
    /*margin-bottom: 20px;*/
    /*border-top-left-radius: 0;*/
    /*border-top-right-radius: 0;*/
    border-radius: 5px;
    border: 0.5px solid lightgrey;
    font-size: 22px;
}


input[type="text"]::placeholder {
    font-size: x-small;
}

input[type="password"]::placeholder {
    font-size: x-small;
}

.input-operator {
    border-radius: 3px;
    font-size: 20px;
    /*background-color: rgb(246,248,250,1);*/
    background-color: var(--std-secondary-blue);
    color : white ;
    padding: 3px;
    cursor: pointer;
    z-index: 900;
}



