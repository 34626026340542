


button {
    border: 0.25px solid lightgrey;
    background-color: rgb(246,248,250,1);
    color : var(--std-primary-grey);
    margin: 3px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    padding: 4px;
}

/*.button {*/
/*    display: inline-flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    background-color: #3f51b5;*/
/*    color: #fff;*/
/*    padding: 10px 20px;*/
/*    border: none;*/
/*    position: relative;*/
/*    font-size: 16px;*/
/*    cursor: pointer;*/
/*    overflow: hidden;*/
/*}*/

/*.button:before,*/
/*.button:after {*/
/*    content: "";*/
/*    background-color: #3f51b5;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    width: 50%;*/
/*}*/

/*.button:before {*/
/*    left: 0;*/
/*    border-top-left-radius: 100% 50%;*/
/*    border-bottom-left-radius: 100% 50%;*/
/*}*/

/*.button:after {*/
/*    right: 0;*/
/*    border-top-right-radius: 100% 50%;*/
/*    border-bottom-right-radius: 100% 50%;*/
/*}*/


.button-small {
    padding: 2px 2px;
    font-size: 10px;
    margin: 1px 1px;
}

button:hover:not([disabled]) {
    background-color: rgb(146,164,53,1);
    color : white;
}

.button-search {
    background-color: var(--std-secondary-orange);
    color: var(--std-primary-grey);
}

.button-info {
    background-color: #40b6f5;
    color: black;
}

.button-cancel {
    background-color: #ff6961;
    color: black;
}

.button-delete {
    background-color: #ff6961;
    color: black;
}

.button-save {
    background-color: #c3fac6;
    color: black;
}

button:disabled {
    background-color: rgb(194, 195, 196);
    color: lightgrey ;
}

.button-login {
    width: 100%;
    border-radius: 5px;
    padding: 12px;
    background: #4ebd24;
    font-size: x-large;
    font-weight: bold;
    color: white;
}
.button-login:disabled {background-color: #f5f4f4;}

.button-icon {
    border: 0.25px solid lightgrey;
    border-radius: 2px;
    padding: 2px;
}

.button-icon:hover:not([disabled]) {
    background-color: rgb(146,164,53,1);
    color : white;
}